<template>
  <section class='px-4 -mt-8 bg-white sm:py-4 sm:mt-0 sm:px-6 lg:px-8'>

    <!-- Container -->
    <div class='max-w-3xl mx-auto lg:max-w-screen-xl'>
      <div class='lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-16'>

        <!-- Section content -->
        <div class='flex flex-col justify-center'>
          <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
            Core values and principles
          </h2>
          <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
            Pellentesque in ipsum id orci porta dapibus. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
          </p>
          
          <!-- Values -->
          <div class='grid max-w-4xl gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-1 sm:mx-auto lg:mx-0 lg:max-w-md'>

            <!-- Value 1 -->
            <div class="flex">
              <div class="w-14">
                <img src='../../../assets/img/checkmark.svg' class='w-7 h-7' alt=''>
              </div>
              <div class="w-full">
                <h5 class="flex items-center text-xl font-semibold text-purple-900">
                  Excellence and Innovation
                </h5>
                <p class="mt-1 text-base text-purple-800">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et
                </p>
              </div>
            </div>

            <!-- Value 2 -->
            <div class="flex">
              <div class="w-14">
                <img src='../../../assets/img/checkmark.svg' class='w-7 h-7' alt=''>
              </div>
              <div class="w-full">
                <h5 class="flex items-center text-xl font-semibold text-purple-900">
                  Creativity
                </h5>
                <p class="mt-1 text-base text-purple-800">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et
                </p>
              </div>
            </div>

            <!-- Value 3 -->
            <div class="flex">
              <div class="w-14">
                <img src='../../../assets/img/checkmark.svg' class='w-7 h-7' alt=''>
              </div>
              <div class="w-full">
                <h5 class="flex items-center text-xl font-semibold text-purple-900">
                  Trustworthiness
                </h5>
                <p class="mt-1 text-base text-purple-800">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et
                </p>
              </div>
            </div>

            <!-- Value 4 -->
            <div class="flex">
              <div class="w-14">
                <img src='../../../assets/img/checkmark.svg' class='w-7 h-7' alt=''>
              </div>
              <div class="w-full">
                <h5 class="flex items-center text-xl font-semibold text-purple-900">
                  Nurturing Individuality
                </h5>
                <p class="mt-1 text-base text-purple-800">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et
                </p>
              </div>
            </div>

          </div>

        </div>

        <!-- Section image -->
        <div class='w-full max-w-xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:mx-0'>
          <div class='aspect-w-3 aspect-h-4'>
            <img class='object-cover w-full h-full rounded-3xl' src='../../../assets/img/values.jpg' alt='values'>
          </div>
        </div>

      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'ValuesPartial'
}
</script>
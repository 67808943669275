<template>
  <section class='px-4 pt-16 sm:px-6 lg:px-8'>
    <!-- Container -->
    <div class='max-w-screen-xl mx-auto'>
      <!-- Page header -->
      <div class="relative">
        <h2 class="max-w-3xl mx-auto text-center text-purple-900 h1">
          Why English with
          <!-- Underlined text -->
          <span class="relative block">
            <span class='relative'>
              <img class='absolute inset-0 transform translate-y-9 sm:translate-y-10 xl:translate-y-12' src='../../../assets/img/underline-simple-light-purple.svg'>
              <span class='relative'>Ms Shamila</span>
            </span>
          </span>
        </h2>
        <p class="max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-800 sm:mt-5">
          Industry-led teaching and specialist careers advice makes studying with Ms Shamila a great choice to set you up 
          for success in your future career. Ms Shamila’s  lessons will improve your confidence, ability and motivation. 
          You’ll be able to use English immediately in real life to make a positive and meaningful change to your life - 
          whatever your reasons for studying.
        </p>
      </div>

      <!-- School images grid -->
      <div class='grid grid-flow-row-dense grid-cols-2 gap-2 sm:grid-cols-3 mt-14 sm:mt-16 lg:mt-24 sm:gap-3 md:gap-4 lg:gap-6'>
        <img class='object-cover w-full rounded-2xl lg:h-80 h-30vw' alt="School grid 01" src="../../../assets/img/school-grid-01.jpg" />

        <img class='object-cover w-full col-span-2 rounded-2xl lg:h-80 h-30vw' src="../../../assets/img/school-grid-02.jpg" alt="School grid 02" />

        <img class='object-cover w-full rounded-2xl lg:h-80 h-30vw' src="../../../assets/img/school-grid-03.jpg" alt="School grid 03" />

        <img class='object-cover w-full rounded-2xl lg:h-80 h-30vw' src="../../../assets/img/school-grid-04.jpg" alt="School grid 04" />

        <img class='object-cover w-full rounded-2xl lg:h-80 h-30vw' src="../../../assets/img/school-grid-05.jpg" alt="School grid 05" />
      </div>

      <!-- About school -->
      <div class='mx-auto prose prose-lg mt-14 sm:mt-16 lg:mt-24 sm:prose-xl'>
        <p>
          Ms Shamila believes that you should be taught by a committed professional who cares about you. Ms Shamila has an 
          inationally recognised English language teaching qualification from Cambridge University UK is highly experienced, 
          and loves teaching her students. You will be welcomed and taught by a kind, friendly and enthusiastic teacher and 
          staff who is interested in you, your needs and your progress. She takes great pleasure in providing the support, 
          feedback and advice you need to achieve your aims with English.
        </p>
      </div>

    </div>

  </section>
</template>

<script>
export default {
  name: 'AboutHeroPartial'
}
</script>
<template>
  <section class='px-4 overflow-hidden bg-white pb-28 sm:pb-36 sm:px-6 lg:px-8'>

    <!-- Container -->
    <div class='max-w-screen-xl mx-auto'>

      <!-- Section header -->
      <div class="px-4 text-center sm:px-6 lg:px-8">
        <h3 class="text-purple-900 h2">
          <span class="block">A skill for everyone to</span>

          <!-- Underlined text -->
          <span class="relative block">
            <span class='relative'>
              <img class='absolute inset-0 transform translate-y-9 sm:translate-y-10 xl:translate-y-12' src='../../../assets/img/underline-simple-light-purple.svg'>
              <span class='relative'>learn and grow</span>
            </span>
          </span>

        </h3>
      </div>

      <!-- Block 1 -->
      <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">
        <div class="relative z-10 flex flex-col justify-center order-2 lg:order-1 lg:col-span-6 lg:text-left">
          <div>
            <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
              Listening skills
            </span>
          </div>
          <div>
            <h1 class="mt-3.5 font-bold text-purple-900 h3">
              Developing young, caring and responsible individuals
            </h1>
            <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
              Lorem ipsum dolor, sit amet consectetur elit do eius mod tempor labore sit amet. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
            </p>
          </div>
        </div>
        <!-- Block graphics -->
        <div class="relative order-1 w-full max-w-xl mx-auto lg:order-2 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div class="hidden lg:block">
            <img src='../../../assets/img/blob-light-yellow.svg' class='absolute inset-0 w-full h-full transform lg:scale-135' alt=''>
          </div>
          <img src='../../../assets/img/dots-large-strip.svg' class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt=''>
          <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mr-0 lg:ml-auto">
            <div class="relative block w-full">
              <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -left-20 xl:w-48 xl:-top-20 xl:-left-20' src='../../../assets/img/dots.svg' alt=''>
              <figure class='aspect-w-12 aspect-h-10 md:order-1'>
                <img src='../../../assets/img/about-01.jpg' class='object-cover object-center w-full h-full shadow-xl rounded-3xl' alt='About 01'>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <!-- Block 2 -->
      <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">  
        <div class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:text-left">
          <div>
            <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
              Reading skills
            </span>
          </div>
          <div>
            <h1 class="mt-3.5 font-bold text-purple-900 h3">
              Getting the attention they deserve
            </h1>
            <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
              Lorem ipsum dolor, sit amet consectetur elit do eius mod tempor labore sit amet. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
            </p>
          </div>
        </div>
        <!-- Block graphics -->
        <div class="relative order-1 w-full max-w-xl mx-auto lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div class="hidden lg:block">
            <img src='../../../assets/img/blob-light-purple.svg' class='absolute inset-0 w-full h-full transform lg:scale-135' alt=''>
          </div>
          <img src='../../../assets/img/dots-large-strip.svg' class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt=''>
          <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mx-0">  
            <div class="relative block w-full">
              <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -right-20 xl:w-48 xl:-top-20 xl:-right-20' src='../../../assets/img/dots.svg' alt=''>
              <figure class='aspect-w-12 aspect-h-10 md:order-1'>
                <img src='../../../assets/img/about-02.jpg' class='object-cover object-center w-full h-full shadow-xl rounded-3xl' alt='About 02'>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <!-- Block 3 -->
      <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">
        <div class="relative z-10 flex flex-col justify-center order-2 lg:order-1 lg:col-span-6 lg:text-left">
          <div>
            <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
              Speaking skills
            </span>
          </div>
          <div>
            <h1 class="mt-3.5 font-bold text-purple-900 h3">
              Every child has their own personalized education program
            </h1>
            <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
              Lorem ipsum dolor, sit amet consectetur elit do eius mod tempor labore sit amet. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
            </p>
          </div>
        </div>
        <!-- Block graphics -->
        <div class="relative order-1 w-full max-w-xl mx-auto lg:order-2 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div class="hidden lg:block">
            <img src='../../../assets/img/blob-light-rose.svg' class='absolute inset-0 w-full h-full transform lg:scale-135' alt=''>
          </div>
          <img src='../../../assets/img/dots-large-strip.svg' class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt=''>
          <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mr-0 lg:ml-auto">
            <div class="relative block w-full">
              <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -left-20 xl:w-48 xl:-top-20 xl:-left-20' src='../../../assets/img/dots.svg' alt=''>
              <figure class='aspect-w-12 aspect-h-10 md:order-1'>
                <img src='../../../assets/img/about-03.jpg' class='object-cover object-center w-full h-full shadow-xl rounded-3xl' alt='About 03'>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <!-- Block 4 -->
      <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">  
        <div class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:text-left">
          <div>
            <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
              Writing skills
            </span>
          </div>
          <div>
            <h1 class="mt-3.5 font-bold text-purple-900 h3">
              Getting the attention they deserve
            </h1>
            <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
              Lorem ipsum dolor, sit amet consectetur elit do eius mod tempor labore sit amet. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
            </p>
          </div>
        </div>
        <!-- Block graphics -->
        <div class="relative order-1 w-full max-w-xl mx-auto lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div class="hidden lg:block">
            <img src='../../../assets/img/blob-light-purple.svg' class='absolute inset-0 w-full h-full transform lg:scale-135' alt=''>
          </div>
          <img src='../../../assets/img/dots-large-strip.svg' class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt=''>
          <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mx-0">  
            <div class="relative block w-full">
              <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -right-20 xl:w-48 xl:-top-20 xl:-right-20' src='../../../assets/img/dots.svg' alt=''>
              <figure class='aspect-w-12 aspect-h-10 md:order-1'>
                <img src='../../../assets/img/about-02.jpg' class='object-cover object-center w-full h-full shadow-xl rounded-3xl' alt='About 02'>
              </figure>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutBlocksPartial'
}
</script>
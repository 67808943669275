<template>
  <section class='relative w-full px-4 py-16 bg-purple-600 sm:py-24 sm:px-6 lg:px-8'>

    <!-- Container -->
    <div class='max-w-screen-xl mx-auto'>
      
      <!-- Section header text -->
      <div>
        <h2 class="max-w-4xl mx-auto text-center text-white h2">
          Giving yourself the best possible start to understanding English 
        </h2>
        <p class="max-w-3xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-50">
          Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
        </p>
      </div>

      <!-- Stats -->
      <div class='mt-12 sm:grid sm:grid-cols-3 sm:gap-x-6 md:gap-x-8 lg:gap-x-12 sm:gap-y-12 gap-y-16'>
        
        <!-- Stat 1 -->
        <div class='text-center'>
          <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>30+</h4>
          <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Teachers</p>
        </div>

        <!-- Stat 2 -->
        <div class='mt-12 text-center sm:mt-0'>
          <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>6+</h4>
          <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Average years of teacher experience</p>
        </div>

        <!-- Stat 3 -->
        <div class='mt-12 text-center sm:mt-0'>
          <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>9</h4>
          <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Average number of students per class</p>
        </div>

        <!-- Stat 4 -->
        <div class='mt-12 text-center sm:mt-0'>
          <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>5:1</h4>
          <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Student to teacher ratio</p>
        </div>

        <!-- Stat 5 -->
        <div class='mt-12 text-center sm:mt-0'>
          <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>4</h4>
          <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Number of Sister Schools</p>
        </div>

        <!-- Stat 6 -->
        <div class='mt-12 text-center sm:mt-0'>
          <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>90%</h4>
          <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Student state exam percentile</p>
        </div>
        
      </div>

    </div>

  </section>
</template>

<script>
export default {
  name: 'AboutStatsPartial'
}
</script>
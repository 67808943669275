<template>
  <div>
    <!-- Home hero -->
    <AboutHeroPartial />
    <!-- Gradient Transition -->
    <div class='w-full h-32 sm:h-40 lg:h-44 bg-gradient-to-b from-purple-25 to-white'></div>
    <!-- About blocks -->
    <AboutBlocksPartial />
    <!-- Stats about learners and programs -->
    <AboutStatsPartial />
    <!-- Section separator-->
    <div class='px-4 bg-purple-600 sm:px-6 lg:px-8'>
      <hr class='max-w-screen-xl mx-auto border-purple-500'>
    </div>
    <!-- Core values -->
    <ValuesPartial />
    <!-- Space for CtA -->
    <div class="bg-white h-40 sm:h-48"></div>
  </div>
</template>

<script>
import AboutHeroPartial from './partials/about/AboutHero.vue'
import AboutBlocksPartial from './partials/about/AboutBlocks.vue'
import AboutStatsPartial from './partials/about/Stats.vue'
import ValuesPartial from './partials/about/Values.vue'

export default {
  name: 'AboutView',
  components: {
    AboutHeroPartial,
    AboutBlocksPartial,
    AboutStatsPartial,
    ValuesPartial
  },
}
</script>